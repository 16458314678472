import * as React from 'react';

import { useState } from 'react';

import { useNavigate } from 'react-router-dom';



// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Box, Button, Alert } from '@mui/material';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const API_URL = `${process.env.REACT_APP_API_URL}`;


export default function LoginForm() {
  const navigate = useNavigate();


  const [showPassword, setShowPassword] = useState(false);
  const [showResponse, setResponse] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const jsonData = {
      email: data.get('email'),
      password: data.get('password'),
    };

    fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonData)
      })
      .then(response => response.json())
      .then(data => {
     
        if(data.status === 'ok'){
          
          // localStorage.setItem('token', data.results.token);
          // sessionStorage.setItem('token', data.results.token);
          sessionStorage.setItem('fname', data.results.fname);
          sessionStorage.setItem('lname', data.results.lname);
          sessionStorage.setItem('email', data.results.email);

          navigate('/send_otp');        
        }else if(data.status === 'error_disabled'){
          setResponse('อีเมลผู้ใช้นี้ถูกยกเลิกใช้งาน กรุณาติดต่อผู้ดูแลระบบ !');
        }else if(data.status === 'error_no_user'){
          setResponse('ไม่พบข้อมูลผู้ใช้งานนนี้ในระบบ !');
        }else if(data.status ==='error_login' ){
          setResponse('กรุณากรอกอีเมลและหัสผ่านให้ถูกต้อง !');
        }else{
          setResponse('เกิดข้อผิดพลาดของระบบ !');
        }
        
      })
      .catch(() =>{
        setResponse('Error Connect API !');
      });
  };

  const handleResetPass = async e => {
    e.preventDefault();
    navigate("/reset_pass");
  }

  


  return (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Stack spacing={3}>
          {showResponse &&<Alert severity="error" ><strong>{showResponse}</strong> </Alert>}
          <TextField  id="outlined-error-helper-text" name="email" label="Email address" />
          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
      
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2,textAlign: 'right' }}>
      
        <Link variant="subtitle2" underline="hover" sx={{cursor: 'pointer'}} onClick={handleResetPass}>
          Forgot Password?
        </Link>
      </Stack>
      <Button type="submit" fullWidth variant="outlined" sx={{ mt: 2, mb: 2 }}>
              Sign In
      </Button>
   
   
      </Box>
      
    </>
  );
}
