import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { redirect } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { TableListHead, TableToolbar } from '../sections/@dashboard/table';
// mock
const API_URL = `${process.env.REACT_APP_API_URL}`;


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'create_date', label: 'Create Date', alignRight: false },
  { id: 'action',label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_response) => _response.fname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserActivatePAge() {

  useEffect(() => {
    const token = localStorage.getItem('token');
    const bearer = "Bearer ";
    
    fetch(`${API_URL}/authen`, {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": bearer+token}
    })
    .then(response => response.json())
    .then(data => {
        if(data.status === 'ok'){
          console.log();
        }else{
            localStorage.clear();
            sessionStorage.clear()
            redirect("/login");
        }
    })
    .catch(() =>{
        localStorage.clear();
        sessionStorage.clear()
        redirect("/login");
    });
  });

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const [userActivate, setUserData] = useState([]);

  const [refreshData, setfreshData] = useState(false);
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    const bearer = "Bearer ";
    fetch(`${API_URL}/users_activate`,{method: 'get',
    headers: {"Content-Type": "application/json", "Authorization": bearer+token}

    }).then(response => response.json())
    .then(data => setUserData(data))
  },[refreshData])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userActivate.length) : 0;

  const filteredUsers = applySortFilter(userActivate, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  // End table

  const MySwal = withReactContent(Swal)


  const handleSend = (data) => {

    const jsonData = {
      fname: data.fname,
      lname: data.lname,
      email: data.email
    };

    console.log(jsonData)

   
    fetch(`${API_URL}/sendmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(res => {
        
        if(res.status === 'ok'){
            MySwal.fire({
              title: 'ส่งอีเมลสำเร็จ',
              text: "success",
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
        }else{
          console.log("error");
        }  
        
       console.log(res.status)         
    })
    .catch((error) =>{
        console.error('Error: ',error);
    });        
    
  }
  
  const handleDel = (data) => {

      const jsonData = {
          id: data.id
      }
      
      fetch(`${API_URL}/del_users_activate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(jsonData)
      })
      .then(response => response.json())
      .then(data => {
      
          MySwal.fire({
            title: 'ลบข้อมูลสำเร็จ',
            text: "success",
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
          setfreshData(!refreshData);

      })
      .catch(() =>{
        console.log('Error Connect API !');
      });
  }

  return (
    <>
     <Helmet>
        <title> Users Activate </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users Activate
          </Typography>
          
        </Stack>

        <Card>
          <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userActivate.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, fname, lname, email, status } = row;

                    return (
                      <TableRow hover key={id} role="checkbox" >
                       
                        <TableCell component="th" scope="row">
                              {fname} {lname}
                        </TableCell>

                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">
                          <Label color={status ? 'warning' : 'error'}> {status ? 'Waiting' : 'Inactive'}</Label>
                        </TableCell>
                        <TableCell align="left">{row.create_date}</TableCell>

                        <TableCell align="center">
                          <IconButton size="large" color="inherit"   onClick={() => handleSend(row)}>
                            <Iconify icon={'material-symbols:mark-email-read-outline'} />
                          </IconButton>
                          <IconButton size="large" color="inherit" onClick={() => handleDel(row)}>
                            <Iconify icon={'mdi:archive-cancel-outline'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                   {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8}/>
                    </TableRow>
                  )}


                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={userActivate.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

     
    </>
  );
}
