import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Avatar from '@mui/material/Avatar';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';

// @mui
import {
    Stack,
    Card,
    Button,
    Container,
    Typography,
    IconButton,
    Box,
    Grid,
    TextField,
    InputAdornment,
    CardContent,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
  } from '@mui/material';

  // components
  import Iconify from '../components/iconify';

  const API_URL = `${process.env.REACT_APP_API_URL}`;

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

export default function ProfilesPage() {

    const [showUserData, setUserData] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [departmentData, setDepartmentData] = useState([]);

    const MySwal = withReactContent(Swal)

    useEffect(() => {
        const token = localStorage.getItem('token');
        const bearer = "Bearer ";
        
        fetch(`${API_URL}/users_email`,{method: 'post',
        headers: {"Content-Type": "application/json", "Authorization": bearer+token},
        }).then(response => response.json())
        .then(data => {
           if(data.status === 'ok'){
             setUserData(data.response)
           }else{
            console.log(data);
           }
        })

        fetch(`${API_URL}/department`,{method: 'post',
            headers: {"Content-Type": "application/json", "Authorization": bearer+token},
            // body: JSON.stringify(jsonData)
            }).then(response => response.json())
            .then(data => setDepartmentData(data)
        );
  
    },[])


    const formSchema = Yup.object().shape({
        department: Yup.string()
            .required("Select Department"),
        fname: Yup.string()
        .required('Enter Menu Name'),
        lname: Yup.string()
        .required('Enter Description'),
        email: Yup.string()
        .required('Enter email')
        .email('Must be a valid email')
      
    })

    const formOptions = { resolver: yupResolver(formSchema) }

    const defaultValues ={ 
        id:'',
        fname: '',
        lname:'',
        email: '',
        password: '',
        department: ''
    }

    const {
    register,
    handleSubmit,
    formState: { errors }
    } = useForm(formOptions, {defaultValues} );



    const onSubmit = (data) => {

        const jsonData = {
            id: showUserData[0].id,
            fname: data.fname,
            lname: data.lname,
            email: data.email,
            department: data.department,
            password: data.password
        }

        fetch(`${API_URL}/users_profile`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        })
          .then(response => response.json())
          .then(data => {
           
            MySwal.fire({
              title: 'บันทึกข้อมูลสำเร็จ',
              text: "success",
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
            
          })
          .catch(() =>{
            console.log('Error Connect API !');
          });
          
    }
    /*
    const [showCheckBox, setCheckBox] = useState({
        email: false,
        line: false
    });
    */
    const navigate = useNavigate();

    const handleCancel = async e => {
        e.preventDefault();
        navigate("/dashboard");
    }
    
      
    const [deptData, setValue] = useState("");
    const handleChangeDepartment = (event) => {
        setValue(event.target.value);
        showUserData[0].department = event.target.value
    };



  return (
    <>
     <Helmet>
        <title>Profiles</title>
      </Helmet>
      
      <Container component="main" maxWidth="md">

        <Card sx={{ maxWidth: "md",my: 1,borderRadius:5 }}>
            <CardContent >
                <Grid container item spacing={2}  md={12}>
                    <Grid item md={12}>
                        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }} md={6}>

                            {showUserData.map((row,index) =>(

                            <Grid container spacing={2} item  md={12} key={index}>
                                <Grid item xs={12} sm={12} md={12} align='center'>
                                    
                                    <Avatar {...stringAvatar(`${row.fname} ${row.lname}`)} sx={{ m: 1,width: 180, height: 180 }}/>

                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>

                                    <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Department</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        {...register('department')}
                                        value={row.department ? row.department : "" }
                                        label="Department"
                                        error={Boolean(errors.department && 'error')} 
                                        onChange={handleChangeDepartment}
                                    >
                                        <MenuItem value="">
                                        <em>None</em>
                                        </MenuItem>
                                        {departmentData && departmentData.map((rows,index) => (
                                        <MenuItem value={rows.name_value} key={index}>{rows.name_text}</MenuItem>
                                        ))}
                                    
                                    </Select>
                                    <Typography>{errors.department?.message} </Typography>
                                    
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                    fullWidth
                                    label="First Name"
                                    defaultValue={row.fname}
                                    {...register('fname')}
                                    helperText={errors.fname?.message}
                                    error={Boolean(errors.fname && 'error')} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                    fullWidth
                                    id="lname"
                                    label="Last Name"
                                    defaultValue={row.lname}
                                    {...register('lname')}
                                    helperText={errors.lname?.message}
                                    error={Boolean(errors.lname && 'error')} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    defaultValue={row.email}
                                    {...register('email')}
                                    helperText={errors.email?.message}
                                    error={Boolean(errors.email && 'error')} 
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    <TextField fullWidth
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        {...register('password')}
                                        helperText={errors.password?.message}
                                        error={Boolean(errors.password && 'error')} 
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                        }}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center"alignItems="center" >
                                        <Stack direction="row" spacing={1}  sx={{my: 5}}>
                                        <Button type="submit" variant="contained" color='primary' >
                                            Save
                                        </Button>
                                        <Button type="button" variant="contained" color='error' onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                        </Stack>
                                    </Box>
                                </Grid>


                            </Grid>
                            ))}

                        </Box>
                    </Grid>
                    
                    
        
                </Grid>

        </CardContent>
        </Card>

      </Container>


 

    </>
   
  );
}