import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import DashboardAppPage from './pages/DashboardAppPage';
import UserPage from './pages/UserPage';
import UserActivatePage from './pages/UserActivatePage';
import LoginPage from './pages/LoginPage';
import MenuPage from './pages/MenuPage';
import RegisterPage from './pages/RegisterPage';
import ResetPassPage from './pages/ResetPassPage';
import EmailActivatePage from './pages/EmailActivatePage';
import OtpPage from './pages/OtpPage';
import LineActivatePage from './pages/LineActivatePage';
import AppConnectPage from './pages/AppConnectPage';
import DatatablePage from './pages/DatatablePage';
import ProfilesPage from './pages/ProfilesPage';


import Page404 from './pages/Page404';


// ----------------------------------------------------------------------
export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'user_activate', element: <UserActivatePage /> },
        { path: 'menu', element: <MenuPage /> },
        { path: 'app_connect', element: <AppConnectPage /> },
        { path: 'datatable', element: <DatatablePage /> },
        { path: 'profiles', element: <ProfilesPage /> },
      ]
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'reset_pass',
      element: <ResetPassPage />,
    },
    {
      path: 'email_activate',
      element: <EmailActivatePage />,
    },
    {
      path: 'line_activate',
      element: <LineActivatePage />,
    },
    {
      path: 'send_otp',
      element: <OtpPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
