import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import  moment  from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Countdown, { zeroPad} from 'react-countdown';
import { Card, CardHeader, Button, CardContent, Grid, TextField, Box, Typography, Stack, Alert,Radio,FormControlLabel } from '@mui/material';
import { encode } from 'js-base64';


const API_URL = `${process.env.REACT_APP_API_URL}`;

export default function OtpForm() {
    
  const navigate = useNavigate();

  const [responseData, setResponseData] = useState([]);
  const [showResponseFailed, setResponseFailed] = useState(false);

  const [responseOTP, setOTP] = useState(false);
  const [responseRadio, setResponseRadio] = useState();
  const [responseDataOTP, setResponseDataOTP] = useState([]);

  useEffect(() => {

    const jsonData = { 
      email: sessionStorage.getItem('email'),
      fname: sessionStorage.getItem('fname'),
      lname: sessionStorage.getItem('lname')
     };
    
    fetch(`${API_URL}/chk_email`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === 'ok'){
        sessionStorage.clear()
        setResponseData(data.response[0]);
      }else{
        sessionStorage.clear()
        navigate("/login");
      }
    })
  },[]);


  const generateString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  
  const handleSubmitSend = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const line = data.get('line');

    const jsonData = { 
      fname: responseData.fname,
      lname: responseData.lname,
      email: responseData.email,
      otp: Math.floor(100000 + Math.random() * 900000),
      ref: generateString(6),
      datet: moment().add(3, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
      time: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    if(email){ // Send Email
        setResponseRadio("mail");
        fetch(`${API_URL}/sendmail_otp`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(jsonData)
      })
      .then(response => response.json())
      .then(res => {
        console.log();    
      })
      .catch((error) =>{
          console.error('Error: ',error);
      });        
    }

    if(line){ // Send Line
        setResponseRadio("line");
        const encodedtest = encode(`OTP : ${jsonData.otp} (REF : ${jsonData.ref})`);
        const API_LINE = `https://app.forthvending.co.th/bot_vaw/bot/pushmsgnew/${responseData.lid}/${encodedtest}`
        fetch(`${API_LINE}`, {
          mode: 'no-cors' // 'cors' by default
        });
    }
        

    fetch(`${API_URL}/crud_otp`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(data => {

      if(data.status === 'ok'){
        setOTP(true)
        setResponseDataOTP(jsonData);
      }else{
        console.log("error");
      }
    })
    .catch(() =>{
        localStorage.clear();
        sessionStorage.clear()
        navigate("/login");
    });


  }


  const formSchema = Yup.object().shape({
    nameotp: Yup.number().typeError('กรุณากรอก OTP 6 หลัก')
    .required('กรุณากรอกรหัส OTP 6 หลัก').min(6,'กรุณากรอกรหัส OTP 6 หลัก')
  })

  const formOptions = { resolver: yupResolver(formSchema) }

  const {register,handleSubmit,formState: { errors }
  } = useForm(formOptions);

  const onSubmitOTP = (data) => {
 
    const jsonData = { 
      email: data.email,
      nameotp: data.nameotp
    };

    fetch(`${API_URL}/check_otp`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(data => {

      if(data.length > 0){

        fetch(`${API_URL}/loginnew`, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(jsonData)
        })
        .then(response => response.json())
        .then(data => {
          if(data.status === 'ok'){
            localStorage.setItem('token', data.results.token);
            /*
            // sessionStorage.setItem('token', data.results.token);
            // sessionStorage.setItem('fname', data.results.fname);
            // sessionStorage.setItem('lname', data.results.lname);
            // sessionStorage.setItem('email', data.results.email);
            */
            navigate('/dashboard');        
          }else{
            setResponseFailed("พบข้อมผิดพลาดของระบบ")
          }
        })
        
      }else{
        setResponseFailed("รหัส OTP ไม่ถูกต้อง")
      }
    
    })
    .catch(() =>{
        localStorage.clear();
        sessionStorage.clear()
        navigate("/login");
    });
    

  }



  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCancel = async e => {
      e.preventDefault();
      sessionStorage.clear();
      navigate("/login");
  }  
  


  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      
      const jsonData = {
          email: responseData.email
        }
        fetch(`${API_URL}/delete_otp`, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(jsonData)
        })
        .then(response => response.json())
        .then(data => {
          console.log();
        })
      
      // Render a complete state
      return <span>รหัส OTP หมดอายุ กรุณารับรหัส OTP ใหม่อีกครั้ง</span>;
    } 

      return (
        <span>
          กรุณากรอก OTP ภายใน : {zeroPad(minutes)}:{zeroPad(seconds)} นาที
        </span>
      );
    
  };

  return (
    <>
        {!responseOTP && <Card sx={{ maxWidth: 500,borderRadius:5,my: 1 }}>
          <CardHeader title="Two-Factor Authentication" align="center"/>
          <CardContent component="form" onSubmit={handleSubmitSend} noValidate sx={{ mt: 1 }}>
            <Typography variant="body2" color="text.secondary" align="center">
                กรุณาเลือกช่องทาง การรับรหัส OTP 
            </Typography>
           
                <Grid container spacing={2}>
               
                    <Grid item sx={{mt: 2}} xs={12} sm={12} md={12} display="flex" justifyContent="center"alignItems="center">
                
                        <FormControlLabel control={<Radio name="email" checked={selectedValue === 'a'} onChange={handleChange} value="a" /> } label="Email" />
                       
                      
                    </Grid>
               
              </Grid>
              <Box display="flex" justifyContent="center"alignItems="center" >
                <Stack direction="row" spacing={2}  sx={{mt: 2}}>
                    <Button type='submit' variant="outlined"color='primary'>     
                   Request OTP 
                  </Button>
                  <Button variant="outlined" color='error' onClick={handleCancel} >
                    Cancel
                  </Button>
                </Stack>
              </Box>
             
          </CardContent>
        </Card>
        }

        {responseOTP &&  
        <Card sx={{ maxWidth: 500,borderRadius:5,my: 1 }}>
          <CardHeader title="Two-Factor Authentication" align="center"/>
          <CardContent component="form" onSubmit={handleSubmit(onSubmitOTP)} noValidate sx={{ mt: 1 }}>
            <Typography variant="body2" color="text.secondary" align="center">
                OTP Ref : {responseDataOTP.ref}
            </Typography>
            <Typography variant="body2" color="error.main" align="center">
               <Countdown date={moment(responseDataOTP.datet).valueOf()} renderer={renderer}/>

            </Typography>
            {showResponseFailed &&<Alert severity="error" sx={{mt: 3}} ><strong>{showResponseFailed}</strong> </Alert>}

                <Grid container spacing={2}>
                    <Grid item sx={{mt: 2}} xs={12} sm={12} md={12} display="flex" justifyContent="center"alignItems="center">
                      <TextField type='number' {...register('nameotp')}
                        helperText={errors.nameotp?.message}
                        error={Boolean(errors.nameotp && 'error')} fullWidth label="กรุณากรอก OTP 6 หลัก" 
                        />
                    </Grid>
                    <Grid  item sx={{mt: 2,display: 'none'}} xs={12} sm={12} md={12} display="flex" justifyContent="center"alignItems="center">
                      <TextField fullWidth label="เลือกส่ง OTP" {...register('sendotp')} value={responseRadio} />
                    </Grid>
                    <Grid item sx={{mt: 2,display: 'none'}} xs={12} sm={12} md={12} display="flex" justifyContent="center"alignItems="center">
                      <TextField fullWidth label="email"  {...register('email')} value={responseDataOTP.email} />
                    </Grid>
               
              </Grid>
              <Box display="flex" justifyContent="center"alignItems="center" >
                <Stack direction="row" spacing={2}  sx={{mt: 2}}>
                  <Button type='submit' variant="outlined"color='primary'>     
                   Confirm OTP
                  </Button>
                  <Button variant="outlined" color='error' onClick={handleCancel} >
                    Cancel
                  </Button>
                </Stack>
              </Box>
             
          </CardContent>
        </Card>  
        }
      
    </>
  );
  
}
