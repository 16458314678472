import { Helmet } from 'react-helmet-async';
// @mui
import { styled,createTheme, ThemeProvider } from '@mui/material/styles';
import { Container,Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

// sections
import { ResetPassForm } from '../sections/auth/resetpass';
// components
import Logo from '../components/logo';

// ----------------------------------------------------------------------

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
         
          backgroundImage: "url(/background.webp)"}

      }
    }
  }
});


const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '50vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPassPage() {

  return (
    <>
      <Helmet>
        <title> Forgot Password </title>
      </Helmet>
      <ThemeProvider theme={theme}>
      <Box align="center">
          <Logo sx={{width:180,marginTop: 2}} />
        </Box>
      <CssBaseline />
        <Container maxWidth="sm">
          <StyledContent>
            <ResetPassForm />
          </StyledContent>
        </Container>
      </ThemeProvider>
    </>
  );
}
