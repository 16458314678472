import { useState,useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import  moment  from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Card, CardHeader, Button, CardContent, Grid, TextField, Box, Typography, Stack, InputAdornment, IconButton,Alert} from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Iconify from '../../../components/iconify';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export default function ResetPassForm() {
    
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal)

  const [emailData, setEmail] = useState("");
  const [fnameData, setFname] = useState("");
  const [lnameData, setLname] = useState("");

  console.log(lnameData);

  const [showPassword, setShowPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFailed, setFailedData] = useState(true);
    
  useEffect(() => {

    const jsonData = {
        email: searchParams.get('email'),
        fname: searchParams.get('fname'),
        lname: searchParams.get('lname'),
      };

    fetch(`${API_URL}/check_email_activate`,{method: 'post',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(jsonData)
      }).then(response => response.json())
      .then(data => {
          setEmail(data.response[0].email)
          setFname(data.response[0].fname)
          setLname(data.response[0].lname)

          }
          )
},[])

  const handleLogin = async e => {
      e.preventDefault();
      navigate("/login");
  }

  const formSchema = Yup.object().shape({
    password: Yup.string()
    .required('No password provided.') 
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+.])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  })

  const formOptions = { resolver: yupResolver(formSchema) }

  const {register,handleSubmit,formState: { errors }
  } = useForm(formOptions);

  const onSubmit = (data) => {

    const jsonData = { 
      fname: fnameData,
      lname: lnameData,
      email: emailData,
      password: data.password ,
      status: 1,
      create_date: moment().format('YYYY-MM-DD HH:mm:ss')
    };
  
    fetch(`${API_URL}/users_activate`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === 'ok'){

        MySwal.fire({
          title: 'คุณยืนยันการลงทะเบียนสำเร็จ',
          text: "success",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
          }
        })
        }else{
            setFailedData("พบข้อมผิดพลาดของระบบ");
        }
        
    })
    .catch(() =>{
        localStorage.clear();
        sessionStorage.clear()
        navigate("/login");
    });
    
  }

  return (
    <>
    
        <Card sx={{ maxWidth: 500,borderRadius:5,my: 1 }}>
          <CardHeader title="ยืนยันการลงทะเบียน" align="center"/>
          <CardContent component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography variant="body2" color="text.secondary" align="center">
                Forthvending Application Portal
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
                ข้อมูลการลงทะเบียน คุณ {fnameData} {lnameData}
            </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
                   {!showFailed && <Alert severity="error" ><strong>{showFailed}</strong> </Alert>}
                </Grid>
                
                <Grid item sx={{mt: 2}} xs={6} sm={6} md={6}>
            
                    <TextField
                    fullWidth
                    id="fname"
                    name="fname"
                    label="First Name"
                    value={fnameData}
                    InputProps={{
                        readOnly: true,
                      }}
                    />
                </Grid>
                <Grid item sx={{mt: 2}} xs={6} sm={6} md={6}>
                    <TextField
                    fullWidth
                    id="lname"
                    label="Last Name"
                    autoComplete="family-name"
                    value={lnameData}
                    {...register('lname')}
                        helperText={errors.lname?.message}
                        error={Boolean(errors.lname && 'error')} 
                    InputProps={{
                      readOnly: true,
                    }}
                    />
                </Grid>
                <Grid item sx={{mt: 2}} xs={12} sm={12} md={12}>
                    
                    <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email Address"
                    value={emailData}
                    InputProps={{
                        readOnly: true,
                      }}
                    autoComplete="email"
                    />
                </Grid>
                <Grid item sx={{mt: 2}} xs={12} sm={12} md={12}>
                  <TextField fullWidth
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      {...register('password')}
                      helperText={errors.password?.message}
                      error={Boolean(errors.password && 'error')} 
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end"alignItems="flex-end" >
                <Stack direction="row" spacing={2}  sx={{mt: 2}}>
                    <Button variant="outlined"color='primary' type='submit'>
                    Confirm
                  </Button>
                  <Button variant="outlined" color='error' onClick={handleLogin} >
                    Cancel
                  </Button>
                </Stack>
              </Box>
          </CardContent>
        </Card>
      
    </>
  );
  
}
