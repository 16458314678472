import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import  moment  from 'moment';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import * as Yup from 'yup'

// @mui
import { TextField, Button, Grid, Alert,Card,CardHeader,CardContent} from '@mui/material';

// ----------------------------------------------------------------------
const API_URL = `${process.env.REACT_APP_API_URL}`;

export default function LoginForm() {

  const navigate = useNavigate();
  
  const [showResponse, setResponse] = useState();
  const MySwal = withReactContent(Swal)

  const formSchema = Yup.object().shape({
    fname: Yup.string()
    .required('Enter first name'),
    lname: Yup.string()
    .required('Enter last name'),
    email: Yup.string()
    .required('Enter email')
    .email('Must be a valid email'),
   
  })

  const formOptions = { resolver: yupResolver(formSchema) }

  const {register,handleSubmit,formState: { errors }
  } = useForm(formOptions);


  const onSubmit = (data) => {

    const jsonData = {
        fname: data.fname,
        lname: data.lname,
        email: data.email,
        status: 1,
        create_date: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    
    fetch(`${API_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(data => {
   
      if(data.status === 'ok'){
        
        fetch(`${API_URL}/sendmail`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        })
        .then(response => response.json())
        .then(res => {
           
          if(res.status === 'ok'){
              // sessionStorage.setItem('send_email', "success");
             
              MySwal.fire({
                title: 'คุณลงทะเบียนสำเร็จ?',
                text: "กรุณายืนยันข้อมูลในอีเมลที่ท่านสมัคร",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/login");
                }
              });

            }           
        })
        .catch((error) =>{
            console.error('Error: ',error);
        });        
        
      }else if(data.status === 'duplicate_email'){
        setResponse('อีเมลนี้เคยลงทะเบียนแล้ว !');
      } else{
        console.log("error");
      }
    })
    .catch((error) =>{
      console.error('Error: ',error);
    });

   

  }; 



  return (
    <>
           <Card sx={{ maxWidth: 500,borderRadius:5 }}>
          <CardHeader title="Register" align="center"/>
          <CardContent component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              {showResponse &&
                <Grid item xs={12} sm={12} sx={{ mt: 2, mb: 2 }}>
                    <Alert severity="error" ><strong>{showResponse}</strong> </Alert>
                </Grid>
                }
                <Grid item xs={12} sm={6}>
                    <TextField
                    autoComplete="given-name"
                    fullWidth
                    id="fname"
                    label="First Name"
                    autoFocus
                    {...register('fname')}
                    helperText={errors.fname?.message}
                    error={Boolean(errors.fname && 'error')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    fullWidth
                    id="lname"
                    label="Last Name"
                    autoComplete="Last name"
                    {...register('lname')}
                        helperText={errors.lname?.message}
                        error={Boolean(errors.lname && 'error')} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    {...register('email')}
                        helperText={errors.email?.message}
                        error={Boolean(errors.email && 'error')} 
                    />
                </Grid>
                
            </Grid>
            <Button
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            >
            Sign Up
            </Button>
            
        </CardContent>
        </Card>
    </>
  );
}
