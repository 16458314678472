import { Helmet } from 'react-helmet-async';
// @mui
import { styled,createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Container,Box } from '@mui/material';

// sections
import { LineActivateForm } from '../sections/auth/activate';
// components
import Logo from '../components/logo';


// ----------------------------------------------------------------------
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
         
          backgroundImage: "url(/background.webp)"        }

      }
    }
  }
});

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '50vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LineActivatePage() {

  return (
    <>
      <Helmet>
        <title> Line Activate </title>
      </Helmet>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container component="main" maxWidth="xs">
            <Box align="center">
              <Logo sx={{width:180,marginTop: 2}} />
            </Box>
                <LineActivateForm />
            </Container>
        </ThemeProvider>
    </>
  );
}
