import { useState, useEffect } from 'react';
import { useNavigate, redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { GoogleLogin, GoogleLogout } from 'react-google-login';

import { gapi } from 'gapi-script';

import moment from 'moment';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// @mui
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { Link, Container, Typography, Divider, Box, Card, CardHeader, CardContent } from '@mui/material';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';

// sections
import { LoginForm } from '../sections/auth/login';


const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {

                    backgroundImage: "url(/background.webp)"
                }

            }
        }
    }
});

const API_URL = `${process.env.REACT_APP_API_URL}`;
const clientIdGoogle = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;

// ----------------------------------------------------------------------

export default function LoginPage() {
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [refreshPage, setfreshPage] = useState(false);
    const MySwal = withReactContent(Swal);


    useEffect(() => {
        const token = localStorage.getItem('token');
        
        const bearer = "Bearer ";
        fetch(`${API_URL}/authen`, {
            method: 'post',
            headers: { "Content-Type": "application/json", "Authorization": bearer + token }

        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    navigate("/dashboard");
                } else {
                    localStorage.clear();
                    sessionStorage.clear()
                    redirect("/login");
                }
            })
    }, []);

    const handleSingUp = async e => {
        e.preventDefault();
        navigate("/register");
    }

    const onSuccess = (res) => {
        console.log('onSuccess');
        setProfile(res.profileObj)
        CheckEmailFromGoogle(res.profileObj)
    }

    const onFailure = (res) => {
        console.log("Failed", res)
    }

    const logOut = () => {
        setProfile(null);
    }

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: clientIdGoogle,
                scope: ""
            })
        };
        gapi.load('client:auth2', start);
    });

    // var accessToken = gapi.auth.getToken().access_token;
    const CheckEmailFromGoogle = (Getdata) => {
        const jsonData = {
            email: Getdata.email,
            fname: Getdata.givenName,
            lname: Getdata.familyName,
            status: 2,
            lastupdate: moment().format('YYYY-MM-DD HH:mm:ss')
        };

        const checkemail = Getdata.email.split('@')[1];

        if (checkemail === 'forth.co.th' || checkemail === 'tao-bin.com') {
            Sigingoogle(jsonData);
        } else {
            fetch(`${API_URL}/chk_email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jsonData)
            })
                .then(response => response.json())
                .then(data => {

                    if (data.status === 'ok') {
                        Sigingoogle(jsonData);
                    } else {
                        setProfile(null);
                        navigate('/login');
                        MySwal.fire({
                            title: 'คุณไม่สามารถเข้าใช้งานได้',
                            text: "เนื่องจากนโยบายของบริษัท",
                            icon: 'warning',
                            showConfirmButton: true,
                        });

                    }

                }).catch(() => {
                    // setResponse('Error Connect API !');
                    console.log("API Error");
                });
        }

    }

    const Sigingoogle = (jsondata) => {
        fetch(`${API_URL}/register_google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsondata)
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    fetch(`${API_URL}/loginnew`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(jsondata)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.status === 'ok') {
                                localStorage.setItem('token', data.results.token);
                                navigate('/dashboard');
                            } else if (data.status === 'error_disabled') {
                                MySwal.fire({
                                    title: 'อีเมลนี้ถูกปิดการใช้งาน',
                                    text: "กรุณาติดต่อแผนก IT",
                                    icon: 'warning',
                                    showConfirmButton: true,
                                });
                            } else {
                                MySwal.fire({
                                    title: 'เกิดข้อผิดพลาดของระบบ',
                                    text: "error",
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            }
                        })

                } else {
                    MySwal.fire({
                        title: 'เกิดข้อผิดพลาดของระบบ !',
                        text: "error",
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            })
            .catch(() => {
                console.log("API Error");
            });

    }

    return (
        <>
            <Helmet>
                <title> Sign In </title>
            </Helmet>
            <ThemeProvider theme={theme}>
                <CssBaseline />

                <Container component="main" maxWidth="xs">
                    <Box align="center">
                        <Logo sx={{ width: 180, marginTop: 2 }} />
                    </Box>
                    <Card sx={{ maxWidth: "sm", my: 1, borderRadius: 5 }}>
                        <CardHeader variant="h2" title="Sign In" align="center" />
                        <CardContent >
                            <LoginForm />
                            <Divider sx={{ my: 3 }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    OR
                                </Typography>
                            </Divider>
                            {
                                /* // register เอง
                              <Typography variant="body2" sx={{ mb: 3 }}>
                                Don’t have an account? {''}
                                <Link variant="subtitle2" onClick={handleSingUp} sx={{cursor: 'pointer'}}>Get Started</Link>
                              </Typography>
                                */
                            }


                            {profile ? (
                                <Box align="center">
                                    <GoogleLogout clientId={clientIdGoogle} buttonText="Log out" onLogoutSuccess={logOut} />
                                </Box>
                            ) : (
                                <Box align="center">
                                    <GoogleLogin
                                        clientId={clientIdGoogle}
                                        buttonText="Login with Google"
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Container>
            </ThemeProvider>
        </>
    );

}
