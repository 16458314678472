import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import  moment  from 'moment';
import * as Yup from 'yup';
import { Card, CardHeader, Button, CardContent, Grid, TextField, Box, Typography, Stack, InputAdornment, IconButton,Alert} from '@mui/material';
import Iconify from '../../../components/iconify';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export default function ResetPassForm() {
  const navigate = useNavigate();
  const [emailData, setEmailData] = useState(false);
  const [resetData, setResetData] = useState(true);
  const [responseData, setResponseData] = useState();
  const [failedData, setFailedData] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async e => {
      e.preventDefault();
      navigate("/login");
  }

  const formSchema = Yup.object().shape({
    email: Yup.string()
    .required('Enter email')
    .email('Must be a valid email')
  })

  const formOptions = { resolver: yupResolver(formSchema) }

  const {register,handleSubmit,formState: { errors }
  } = useForm(formOptions);

  const onSubmit = (data) => {
    const jsonData = { 
      email: data.email 
    };

    fetch(`${API_URL}/find_email`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === 'ok'){
          setResponseData(data.response[0]);
          setEmailData(true);
          setResetData(false);
        }else{
            setFailedData("ไม่พบข้อมูลในระบบ");
        }
    })
    .catch(() =>{
        localStorage.clear();
        sessionStorage.clear()
        navigate("/login");
    });

  }


  
  const onResetSubmit = async e => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const jsonData = {
      email: data.get('email'),
      fname: data.get('fname'),
      lname: data.get('lname'),
      password: data.get('password'),
      lastupdate: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    fetch(`${API_URL}/reset_pass`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === 'success'){
          navigate("/login");  
        }else{
          console.log("error");
        }
    })
    .catch(() =>{
        localStorage.clear();
        sessionStorage.clear()
        navigate("/login");
    });



  }


  return (
    <>
      {!emailData && 
        <Card sx={{ maxWidth: 500,borderRadius:5 }}>
          <CardHeader title="Find Your Account"/>
          <CardContent component="form" onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="body2" color="text.secondary">
              Please enter your Email address to search for your account.
            </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
                    {failedData &&<Alert severity="error" ><strong>{failedData}</strong> </Alert>}
                </Grid>
                <Grid item sx={{mt: 2}} xs={12} sm={12} md={12}>
                    <TextField
                    fullWidth
                    id="email"
                    label="Email Address"
                    {...register('email')}
                    helperText={errors.email?.message}
                    error={Boolean(errors.email && 'error')} 
                    autoComplete="email"
                    />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end"alignItems="flex-end" >
                <Stack direction="row" spacing={2}  sx={{mt: 2}}>
                  <Button variant="outlined"color='primary' type='submit'>
                    search
                  </Button>
                  <Button variant="outlined" color='error' onClick={handleLogin} >
                    Cancel
                  </Button>
                </Stack>
              </Box>
          </CardContent>
        </Card>
      }
      {!resetData && 
        <Card sx={{ maxWidth: 500,borderRadius:5 }}>
        <CardHeader title="Reset Password"/>
        <CardContent component="form" onSubmit={onResetSubmit}>
          <Typography variant="body2" color="text.secondary">
            Reset Password
          </Typography>
            <Grid container spacing={2}>

              <Grid item sx={{mt: 2}} xs={6} sm={6} md={6}>
                  <TextField
                  fullWidth
                  id="fname"
                  label="First Name"
                  {...register('fname')}
                  helperText={errors.fname?.message}
                  error={Boolean(errors.fname && 'error')} 
                  autoComplete="fname"
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={responseData.fname}
                  />
              </Grid>
              <Grid item sx={{mt: 2}} xs={6} sm={6} md={6}>
                  <TextField
                  fullWidth
                  id="lname"
                  label="Last Name"
                  {...register('lname')}
                  helperText={errors.lname?.message}
                  error={Boolean(errors.lname && 'error')} 
                  autoComplete="lname"
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={responseData.lname}
                  />
              </Grid>
              <Grid item sx={{mt: 2}} xs={12} sm={12} md={12}>
                  <TextField
          
                    fullWidth
                    id="email"
                    label="Email Address"
                    {...register('email')}
                    helperText={errors.email?.message}
                    error={Boolean(errors.email && 'error')} 
                    autoComplete="email"
                    InputProps={{
                      readOnly: true,
                    }}
                    defaultValue={responseData.email}

                  />
              </Grid>
              <Grid item sx={{mt: 2}} xs={12} sm={12} md={12}>
                  <TextField fullWidth
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      {...register('password')}
                      helperText={errors.password?.message}
                      error={Boolean(errors.password && 'error')} 
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                        required: true,
                      }}
                  />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end"alignItems="flex-end" >
              <Stack direction="row" spacing={2}  sx={{mt: 2}}>
                <Button variant="outlined"color='primary' type='submit'>
                  Confirm
                </Button>
                <Button variant="outlined" color='error' onClick={handleLogin} >
                  Cancel
                </Button>
              </Stack>
            </Box>
        </CardContent>
      </Card>
      }

      
    </>
  );
  
}
