import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { filter } from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { json, redirect } from 'react-router-dom';

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Grid,
    TextField,
    FormControlLabel,
    Switch,
    InputAdornment,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Collapse
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// components
import Iconify from '../components/iconify';
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';

// sections
import { TableListHead, TableToolbar } from '../sections/@dashboard/table';
// mock
const API_URL = `${process.env.REACT_APP_API_URL}`;


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'fullname', label: 'Fullname', alignRight: false },
    { id: 'email', label: 'E-mail', alignRight: false },
    { id: 'department', label: 'Department', alignRight: false },
    { id: 'lid', label: 'Line-OTP', alignRight: false },
    { id: 'twofact', label: '2FA', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'lastupdate', label: 'Last Update', alignRight: false },
    { id: 'action', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_response) => _response.fname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function DatatablePage() {


    useEffect(() => {
        const token = localStorage.getItem('token');
        const bearer = "Bearer ";

        fetch(`${API_URL}/authen`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": bearer + token }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    console.log();
                } else {
                    localStorage.clear();
                    sessionStorage.clear()
                    redirect("/login");
                }
            })
            .catch(() => {
                localStorage.clear();
                sessionStorage.clear()
                redirect("/login");
            });
    });

    // start table
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showUserData, setUserData] = useState([]);
    const [refreshData, setfreshData] = useState(false);


    useEffect(() => {
        fetch(`${API_URL}/users`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(data => setUserData(data))
    }, [refreshData])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - showUserData.length) : 0;
    const filteredUsers = applySortFilter(showUserData, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredUsers.length && !!filterName;
    // End table


    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [userModal, setModalUser] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [appData, setAppData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);

    const [checkedFunc, setCheckFunc] = useState(true);
    const [uncheckedFunc, setUnCheckFunc] = useState(false);

    const [functionData, setFunctionData] = useState([]);


    const MySwal = withReactContent(Swal);

    const handleClickOpen = (info) => {

        setTimeout(
            () => setOpen(true),
            200
        );

        // setOpen(true);

        setModalUser(info);

        const jsonData = {
            id: info.id,
            user_mail: info.email
        };

        const token = localStorage.getItem('token');
        const bearer = "Bearer ";

        fetch(`${API_URL}/menus_rule`, {
            method: 'post',
            headers: { "Content-Type": "application/json", "Authorization": bearer + token },
            body: JSON.stringify(jsonData)

        }).then(response => response.json())
            .then(data => setMenuData(data)
            )
        /*
        fetch(`${API_URL}/apps_rule`,{method: 'post',
        headers: {"Content-Type": "application/json", "Authorization": bearer+token},
        body: JSON.stringify(jsonData)
        }).then(response => response.json())
        .then(data => setAppData(data)
        );
    */
        fetch(`${API_URL}/department`, {
            method: 'post',
            headers: { "Content-Type": "application/json", "Authorization": bearer + token },
            body: JSON.stringify(jsonData)
        }).then(response => response.json())
            .then(data => setDepartmentData(data)
            );


        // Test New Function
        fetch(`${API_URL}/master_app_function_new`, {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(jsonData)
        }).then(response => response.json())
            .then(data => {
                if (data) {
                    setAppData(data)
                } else {
                    console.log(data);
                }
            })

    };

    const formSchema = Yup.object().shape({

        fname: Yup.string()
            .required('Enter Menu Name'),
        lname: Yup.string()
            .required('Enter Description'),
        email: Yup.string()
            .required('Enter email')
            .email('Must be a valid email')

    })


    const formOptions = { resolver: yupResolver(formSchema) }
    const defaultValues = {
        id: '',
        fname: '',
        lname: '',
        email: '',
        password: '',
        lid: '',
        twofact: '',
        status: '',
        department: '',
        detail_menu: "",
        detail_function: "",
    }

    const {
        register,
        handleSubmit,
        resetField,
        formState: { errors }
    } = useForm(formOptions, { defaultValues });

    const onSubmit = (data) => {

        console.log(data);


        const responseMenu = data.detail_menu.map((row) => {
            if (row.menu_id === false) {
                return "";
            }

            const response = {
                user_id: row.user_id,
                menu_id: row.menu_id
            }
            return response;
        });

        const detailMenu = responseMenu.filter(e => e !== '');


        const responseFunction = data.detail_function.map((row) => {
            if (row.add === false && row.edit === false && row.delete === false && row.print_export === false && row.reject === false && row.upload === false && row.approve === false && row.all === false) {
                return "";
            }

            if (row.all === true) {
                const response = {
                    app_id: row.app_id,
                    menu_id: row.menu_id,
                    function_name: row.function_name,
                    add: 1,
                    edit: 1,
                    delete: 1,
                    print_export: 1,
                    reject: 1,
                    upload: 1,
                    approve: 1,
                    status: 1
                }
                return response

            }

            const response = {
                app_id: row.app_id,
                menu_id: row.menu_id,
                function_name: row.function_name,
                add: row.add,
                edit: row.edit,
                delete: row.delete,
                print_export: row.print_export,
                reject: row.reject,
                upload: row.upload,
                approve: row.approve,
                status: 1
            }
            return response

        })

        console.log(responseFunction);

        const detailFunction = responseFunction.filter(e => e !== '');


        const jsonData = {
            id: data.id,
            fname: data.fname,
            lname: data.lname,
            lid: data.lid,
            email: data.email,
            password: data.password,
            department: data.department,
            detail_menu: detailMenu,
            detail_function: detailFunction,
            status: data.status,
            twofact: data.twofact,
            create_date: moment().format('YYYY-MM-DD HH:mm:ss')
        }


        /*
        if (data.id !== '' && data.password !== '') {
            fetch(`${API_URL}/crud_user_reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jsonData)
            })
                .then(response => response.json())
                .then(data => {

                    setOpen(false);
                    MySwal.fire({
                        title: 'บันทึกข้อมูลสำเร็จ',
                        text: "success",
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    clearArray();

                    setfreshData(!refreshData);

                })
                .catch(() => {
                    console.log('Error Connect API !');
                });
        } else {

            fetch(`${API_URL}/crud_user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jsonData)
            })
                .then(response => response.json())
                .then(data => {

                    setOpen(false);

                    MySwal.fire({
                        title: 'บันทึกข้อมูลสำเร็จ',
                        text: "success",
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    clearArray();

                    setfreshData(!refreshData);

                })
                .catch(() => {
                    console.log('Error Connect API !');
                });

        }

        */

    }
    

    const handleClose = () => {

        resetField("id");
        resetField("fname");
        resetField("lname");
        resetField("email");
        resetField("password");
        resetField("lid");
        resetField("twofact");
        resetField("status");
        resetField("department");
        resetField("detail_menu");
        // resetField("detail_function");

        clearArray();

        setOpen(false);
        setfreshData(!refreshData);
    };

    const clearArray = () => {
        const responseFunction = appData[0].user_permission.map((row, index) => {

            resetField(`detail_function.${index}.app_id`);
            resetField(`detail_function.${index}.menu_id`);
            resetField(`detail_function.${index}.function_name`);
            resetField(`detail_function.${index}.add`);
            resetField(`detail_function.${index}.edit`);
            resetField(`detail_function.${index}.delete`);
            resetField(`detail_function.${index}.print_export`);
            resetField(`detail_function.${index}.reject`);
            resetField(`detail_function.${index}.upload`);
            resetField(`detail_function.${index}.approve`);

            return "";
        });
    }


    const [deptData, setValue] = useState("");
    const handleChangeDepartment = (event) => {
        userModal.department = event.target.value
        setValue(event.target.value);
    };


    // Checkbox Menu
    const [selectedMemu, setSelectedMenu] = React.useState([]);
    const isSelectedMenu = (name) => selectedMemu.indexOf(name) !== -1;

    const handleClickMenu = (event, name) => {

        const selectedIndex = selectedMemu.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedMemu, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedMemu.slice(1));
        } else if (selectedIndex === selectedMemu.length - 1) {
            newSelected = newSelected.concat(selectedMemu.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedMemu.slice(0, selectedIndex),
                selectedMemu.slice(selectedIndex + 1),
            );
        }
        setSelectedMenu(newSelected);
    }
    // End Checkbox Menu


    // Checkbox App
    const [selectedApp, setSelectedApp] = React.useState([]);
    const isSelectedApp = (name) => selectedApp.indexOf(name) !== -1;

    const handleClickApp = (event, name, data) => {

        const selectedIndex = selectedApp.indexOf(name);

        console.log(selectedIndex);

        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedApp, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedApp.slice(1));
        } else if (selectedIndex === selectedApp.length - 1) {
            newSelected = newSelected.concat(selectedApp.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedApp.slice(0, selectedIndex),
                selectedApp.slice(selectedIndex + 1),
            );
        }

        setSelectedApp(newSelected);
    }
    // End Checkbox App


    const handleClickCloseUser = (data) => {
        let chkstatus = '0';
        if (data.status === 0) {
            chkstatus = '1';
        }

        const jsonData = {
            id: data.id,
            email: data.email,
            status: chkstatus,
            create_date: moment().format('YYYY-MM-DD HH:mm:ss')
        }

        fetch(`${API_URL}/crud_user_status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        })
            .then(response => response.json())
            .then(data => {

                setfreshData(!refreshData);

            })
            .catch(() => {
                console.log('Error Connect API !');
            });
    }


    const handleChangeCheck = (data) => {

        /* 
        console.log(data.function_id);
        console.log(appData)
    
        setAppData.app_id = 2
        appData[0].user_permission[2].add_permit = 1;
        console.log(appData[0].user_permission[2])
        */
        setCheckFunc(true)

        // const test = e.target.getAttribute('data-item');

        // console.log(test);
    }

    return (
        <>
            <Helmet>
                <title> Users </title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Users
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
                        New User
                    </Button>

                </Stack>

                <Card>
                    <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <TableListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={showUserData.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { id } = row;

                                        return (
                                            <TableRow hover key={id} tabIndex={-1}>

                                                <TableCell component="th" scope="row">
                                                    {row.fname} {row.lname}
                                                </TableCell>

                                                <TableCell align="left">{row.email}</TableCell>
                                                <TableCell align="left">{row.department}</TableCell>
                                                <TableCell align="left">{row.lid ? 'Active' : 'None'}</TableCell>
                                                <TableCell align="left">{row.twofact ? 'Active' : 'None'}</TableCell>
                                                <TableCell align="left">
                                                    <Label color={row.status ? 'success' : 'error'}> {row.status ? 'Active' : 'Inactive'}</Label>
                                                </TableCell>
                                                <TableCell align="left">{row.lastupdate}</TableCell>

                                                <TableCell align="center">
                                                    <IconButton size="large" color="inherit" onClick={() => handleClickOpen(row)}>
                                                        <Iconify icon={'material-symbols:edit-square-outline'} />
                                                    </IconButton>
                                                    <IconButton size="large" color="inherit" onClick={() => handleClickCloseUser(row)}>
                                                        <Iconify icon={'mdi:archive-cancel-outline'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={8} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={showUserData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Dialog maxWidth="md" open={open} scroll={scroll}>
                {userModal.fname ? <DialogTitle>Edit ({userModal.fname} {userModal.lname}) </DialogTitle> : <DialogTitle>Add</DialogTitle>}

                <DialogContent dividers={scroll === 'paper'}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>

                        <Grid container spacing={2}>
                            <input  {...register('id')}
                                defaultValue={userModal.id}
                                type="hidden" />

                            <Grid item xs={12} sm={12} md={12}>

                                <FormControl fullWidth>
                                    
                                    <InputLabel id="demo-simple-select-label">Department</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        {...register('department')}
                                        value={userModal.department ? userModal.department : ""}
                                        label="Department"
                                        error={Boolean(errors.department && 'error')}
                                        onChange={handleChangeDepartment}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {departmentData && departmentData.map((row, index) => (
                                            <MenuItem value={row.name_value} key={index}>{row.name_text}</MenuItem>
                                        ))}

                                    </Select>
                                    <Typography>{errors.department?.message} </Typography>

                                </FormControl>

                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    defaultValue={userModal.fname}
                                    {...register('fname')}
                                    helperText={errors.fname?.message}
                                    error={Boolean(errors.fname && 'error')}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="lname"
                                    label="Last Name"
                                    defaultValue={userModal.lname}
                                    {...register('lname')}
                                    helperText={errors.lname?.message}
                                    error={Boolean(errors.lname && 'error')}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    defaultValue={userModal.email}
                                    {...register('email')}
                                    helperText={errors.email?.message}
                                    error={Boolean(errors.email && 'error')}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="lid"
                                    label="Line UID"
                                    defaultValue={userModal.lid}
                                    {...register('lid')}
                                    helperText={errors.lid?.message}
                                    error={Boolean(errors.lid && 'error')}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    {...register('password')}
                                    helperText={errors.password?.message}
                                    error={Boolean(errors.password && 'error')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                        </Grid>

                        <Accordion sx={{ mt: 5 }}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>สิทธิ์การเข้างาน Menu</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Check</TableCell>

                                                <TableCell>Menu Name</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {menuData.map((row, index) => {
                                                const isItemSelectedMenu = isSelectedMenu(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClickMenu(event, row.id)}
                                                        role="checkbox"
                                                        aria-checked={row.menu_status === '1' ? !isItemSelectedMenu : isItemSelectedMenu}
                                                        tabIndex={-1}
                                                        key={index}
                                                        selected={row.menu_status === '1' ? !isItemSelectedMenu : isItemSelectedMenu}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <input type="hidden" value={userModal.id} {...register(`detail_menu.${index}.user_id`)} />

                                                            <Checkbox
                                                                color="primary"
                                                                checked={row.menu_status === '1' ? !isItemSelectedMenu : isItemSelectedMenu}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                                value={row.id}
                                                                {...register(`detail_menu.${index}.menu_id`)}
                                                            />
                                                        </TableCell>

                                                        <TableCell align="left">{row.menu_name}</TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>



                        <Accordion >
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>สิทธิ์การเข้างาน Apps</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {appData.map((row, index) => {

                                    return (
                                        <Accordion key={index} >
                                            <AccordionSummary
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>{row.app_name} </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Table size="small" aria-label="purchases">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Function Name</TableCell>
                                                            <TableCell>Add</TableCell>
                                                            <TableCell>Edit</TableCell>
                                                            <TableCell>Delete</TableCell>
                                                            <TableCell>Approve</TableCell>
                                                            <TableCell>Reject</TableCell>
                                                            <TableCell>Print/Export</TableCell>
                                                            <TableCell>Upload</TableCell>
                                                            <TableCell>ALL</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row.user_permission.map((rows, indexs) => {
                                                            const isItemSelectedApp = isSelectedApp(rows.function_id);

                                                            const labelId = `enhanced-table-checkbox-${indexs}`;
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    onClick={(event) => handleClickApp(event, rows.function_id, rows)}
                                                                    role="checkbox"
                                                                    aria-checked={rows
                                                                        .add_permit === 1 && rows
                                                                            .edit_permit === 1 && rows
                                                                                .delete_permit === 1 && rows
                                                                                    .approve_permit === 1 && rows
                                                                                        .reject_permit === 1 && rows
                                                                                            .print_export_permit === 1 && rows
                                                                                                .upload_permit === 1 ? !isItemSelectedApp : isItemSelectedApp}
                                                                    tabIndex={-1}
                                                                    key={indexs}
                                                                    selected={rows
                                                                        .add_permit === 1 && rows
                                                                            .edit_permit === 1 && rows
                                                                                .delete_permit === 1 && rows
                                                                                    .approve_permit === 1 && rows
                                                                                        .reject_permit === 1 && rows
                                                                                            .print_export_permit === 1 && rows
                                                                                                .upload_permit === 1 ? !isItemSelectedApp : isItemSelectedApp}
                                                                    sx={{ cursor: 'pointer' }}
                                                                >
                                                                    <TableCell align="left">{rows.function_name}</TableCell>

                                                                    <TableCell>

                                                                        <Checkbox
                                                                            defaultChecked={rows
                                                                                .add_permit === 1 ? checkedFunc : uncheckedFunc}
                                                                            {...register(`detail_function.${rows.function_id}.add`)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            defaultChecked={rows
                                                                                .edit_permit === 1 ? checkedFunc : uncheckedFunc}
                                                                            {...register(`detail_function.${rows.function_id}.edit`)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            defaultChecked={rows
                                                                                .delete_permit === 1 ? checkedFunc : uncheckedFunc}
                                                                            {...register(`detail_function.${rows.function_id}.delete`)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            defaultChecked={rows
                                                                                .approve_permit === 1 ? checkedFunc : uncheckedFunc}
                                                                            {...register(`detail_function.${rows.function_id}.approve`)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            defaultChecked={rows
                                                                                .reject_permit === 1 ? checkedFunc : uncheckedFunc}
                                                                            {...register(`detail_function.${rows.function_id}.reject`)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            defaultChecked={rows
                                                                                .print_export_permit === 1 ? checkedFunc : uncheckedFunc}
                                                                            {...register(`detail_function.${rows.function_id}.print_export`)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            defaultChecked={rows
                                                                                .upload_permit === 1 ? checkedFunc : uncheckedFunc}
                                                                            {...register(`detail_function.${rows.function_id}.upload`)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell padding="checkbox">

                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={rows
                                                                                .add_permit === 1 && rows
                                                                                    .edit_permit === 1 && rows
                                                                                        .delete_permit === 1 && rows
                                                                                            .approve_permit === 1 && rows
                                                                                                .reject_permit === 1 && rows
                                                                                                    .print_export_permit === 1 && rows
                                                                                                        .upload_permit === 1 ? !isItemSelectedApp : isItemSelectedApp}
                                                                            inputProps={{
                                                                                'aria-labelledby': labelId,
                                                                            }}
                                                                            {...register(`detail_function.${rows.function_id}.all`)}
                                                                        />
                                                                    </TableCell>


                                                                </TableRow>
                                                            );

                                                        })}

                                                    </TableBody>
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                }

                                )}

                            </AccordionDetails>
                        </Accordion>


                        <Grid item xs={12}>
                            <FormControlLabel {...register('twofact')} defaultValue={userModal.twofact} sx={{ mt: 1, width: 15 }} control={(userModal.twofact === 1) && <Switch defaultChecked /> || <Switch checked />} label="2FA" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel {...register('status')} defaultValue={userModal.status} sx={{ mt: 1, width: 15 }} control={(userModal.status === 1) && <Switch defaultChecked /> || <Switch checked />} label="Status" />
                        </Grid>


                        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" >
                            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>

                                <Button type="submit" variant="contained" color='primary' >
                                    Save
                                </Button>
                                <Button variant="contained" color='error' onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>

                </DialogContent>

            </Dialog>
        </>
    );
}


