import { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { styled,alpha } from '@mui/material/styles';
// @mui
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover,Badge } from '@mui/material';

// ----------------------------------------------------------------------
const API_URL = `${process.env.REACT_APP_API_URL}`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}`,
  };
}


export default function AccountPopover() {

  const navigate = useNavigate();
  const [showUserData, setUserData] = useState([]);

  const [open, setOpen] = useState(null);


  useEffect(() => {
      const token = localStorage.getItem('token');
      const bearer = "Bearer ";
      
      fetch(`${API_URL}/users_email`,{method: 'post',
      headers: {"Content-Type": "application/json", "Authorization": bearer+token},
      }).then(response => response.json())
      .then(data => {
        if(data.status === 'ok'){
          setUserData(data.response)
        }else{
          console.log(data);
        }
      })
  },[])

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    setOpen(null);


    navigate("/login");

  };


  const handleProfiles = () => {
    setOpen(null);
    navigate("/dashboard/profiles");
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.1),
            },
          }),
        }}
      >
        <Stack direction="row" spacing={2}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            {showUserData.map((row,index) =>
                  <Avatar key={index}{...stringAvatar(`${row.fname}`)} />
            )}
          </StyledBadge>
        
          </Stack>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {showUserData.map((row,index) =>
        <Box sx={{ my: 1.5, px: 2.5 }} key={index}>
          <Typography variant="subtitle2" noWrap>
            {row.fname} {row.lname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {row.email}
          </Typography>
        </Box>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleProfiles} sx={{ m: 1 }}>
          Profiles
        </MenuItem>

        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
