import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { filter } from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Grid,
    TextField,
    FormControlLabel,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails

} from '@mui/material';


// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';


// sections
import { TableListHead, TableToolbar } from '../sections/@dashboard/table';

// mock
const API_URL = `${process.env.REACT_APP_API_URL}`;


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'appname', label: 'Apps Name', alignRight: false },
    { id: 'appdesc', label: 'Description', alignRight: false },
    { id: 'appurl', label: 'Path', alignRight: false },
    { id: 'appicon', label: 'Apps Icon', alignRight: false },
    { id: 'app_bgstyle', label: 'Apps Colors', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_response) => _response.appname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('token');
        const bearer = "Bearer ";

        fetch(`${API_URL}/authen`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": bearer + token }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    console.log();
                } else {
                    localStorage.clear();
                    sessionStorage.clear()
                    navigate("/login");
                }
            })
            .catch(() => {
                localStorage.clear();
                sessionStorage.clear()
                navigate("/login");
            });
    });


    // start table
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [showAppData, setAppData] = useState([]);

    const [refreshData, setfreshData] = useState(false);

    const MySwal = withReactContent(Swal)


    useEffect(() => {
        const token = localStorage.getItem('token');
        const bearer = "Bearer ";
        fetch(`${API_URL}/app`, {
            method: 'get',
            headers: { "Content-Type": "application/json", "Authorization": bearer + token }

        }).then(response => response.json())
            .then(data => setAppData(data))
    }, [refreshData])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - showAppData.length) : 0;

    const filteredUsers = applySortFilter(showAppData, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;
    // End table


    const [openModal, setOpenModal] = React.useState(false);
    const [showModalAppData, setModalApp] = useState(false);
    const [showModalFunctionData, setModalFunction] = useState({
        rows: []
    });

    const handleClickOpen = (info) => {

        setModalApp(info);


        setTimeout(
            () => setOpenModal(true),
            200
        );

        const jsonData = {
            app_id: info.id
        }

        fetch(`${API_URL}/get_function_name`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        })
            .then(response => response.json())
            .then(data => {
                setModalFunction({
                    rows: data
                });
            }).catch((error) => {
                console.error('Error: ', error);
            });
    }


    const formSchema = Yup.object().shape({
        app_name: Yup.string()
            .required('Enter Menu Name'),
        app_desc: Yup.string()
            .required('Enter Description'),
        app_icon: Yup.string()
            .required('Enter Font Awesome Icon'),
        app_url: Yup.string()
            .required('Enter Path'),
        detail_function: Yup.array()
            .required('Enter')
    })

    const formOptions = { resolver: yupResolver(formSchema) }

    const {
        register,
        handleSubmit,
        resetField,
        formState: { errors }
    } = useForm(formOptions, {
        defaultValues: {
            id: '',
            app_name: '',
            app_desc: '',
            app_icon: '',
            app_url: '',
            app_bgstyle: '',
            detail_function: [{
                function_name: '',
                function_detail: ''
            }]
        }
    });

    const onSubmit = (data) => {

        const jsonData = {
            id: data.id,
            app_name: data.app_name,
            app_desc: data.app_desc,
            app_icon: data.app_icon,
            app_bgstyle: data.app_bgstyle,
            app_url: data.app_url,
            status: data.status,
            // detail_function: detailFunction,
            create_date: moment().format('YYYY-MM-DD HH:mm:ss')
        };


        fetch(`${API_URL}/crud_app`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        })
            .then(response => response.json())
            .then(res => {

                if (res.status === 'success') {

                    const responseFunction = data.detail_function.map((row) => {

                        if (row.function_id === undefined) {
                            return "";

                        }
                        const response = {
                            appID: res.response_id,
                            funcID: row.function_id,
                            funcName: row.function_name,
                            funcDetail: row.function_detail

                        }
                        return response;
                    });


                    const detailFunction = responseFunction.filter(e => e !== '');

                    const jsonDataFunc = {
                        detail_function: detailFunction
                    }


                    fetch(`${API_URL}/crud_function`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(jsonDataFunc)
                    })
                        .then(response => response.json())
                        .then(res1 => {
                            if (res1.status === 'success') {
                                setOpenModal(false);
                                MySwal.fire({
                                    title: 'บันทึกข้อมูลสำเร็จ',
                                    text: "success",
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                                clearArray();

                                setfreshData(!refreshData);
                            } else {
                                console.error('Error: API ');
                            }


                        }).catch((error) => {
                            console.error('Error: ', error);
                        });

                } else {
                    console.log("error");
                }

            })
            .catch((error) => {
                console.error('Error: ', error);
            });


    }

    const handleClose = () => {
        resetField("id");
        resetField("app_name");
        resetField("app_desc");
        resetField("app_icon");
        resetField("app_bgstyle");
        resetField("app_url");

        // Reset Name  Array
        clearArray();

        setOpenModal(false);

    };

    const clearArray = () => {
        const responseFunction = showModalFunctionData.rows.map((row, index) => {

            resetField(`detail_function.${index}.function_name`);
            resetField(`detail_function.${index}.function_id`);
            resetField(`detail_function.${index}.function_detail`);

            return "";
        });
    }


    const handleChangeStatus = (data) => {

        let chkstatus = '0';
        if (data.status === 0) {
            chkstatus = '1';
        }

        const jsonData = {
            id: data.id,
            app_name: data.appname,
            app_desc: data.appdesc,
            app_icon: data.appicon,
            app_bgstyle: data.appbgstyle,
            app_url: data.appurl,
            status: chkstatus,
            create_date: moment().format('YYYY-MM-DD HH:mm:ss')
        };


        fetch(`${API_URL}/crud_app`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        })
            .then(response => response.json())
            .then(data => {

                if (data.status === 'success') {
                    setfreshData(!refreshData);
                }

            })
            .catch((error) => {
                console.error('Error: ', error);
            });

    }

    // Table Add Row

    const handleChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...showModalFunctionData.rows];
        rows[idx] = {
            [name]: value
        };
        setModalFunction({
            rows
        });
    };



    const handleAddRow = () => {
        const item = {
            name: "",
            function_detail: ""
        };
        setModalFunction({
            rows: [...showModalFunctionData.rows, item]
        });
    };

    /* 
    const handleRemoveRow = () => {
      setModalFunction({
        rows: showModalFunctionData.rows.slice(0, -1)
      });
    };
    */
    const handleRemoveSpecificRow = (idx) => () => {

        resetField(`detail_function.${idx}.function_name`);
        resetField(`detail_function.${idx}.function_id`);
        resetField(`detail_function.${idx}.function_detail`);

        const rows = [...showModalFunctionData.rows]
        rows.splice(idx, 1)
        setModalFunction({ rows })
    }



    return (
        <>
            <Helmet>
                <title> Apps </title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Apps
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
                        New Apps
                    </Button>
                </Stack>


                <Card>
                    <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <TableListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={showAppData.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { id, appname, appdesc, appurl, appicon, appbgstyle, status } = row;

                                        return (
                                            <TableRow hover key={id} tabIndex={-1}>

                                                <TableCell component="th" scope="row">
                                                    {appname}
                                                </TableCell>

                                                <TableCell align="left">{appdesc}</TableCell>
                                                <TableCell align="left">{appurl}</TableCell>
                                                <TableCell align="left">{appicon}</TableCell>
                                                <TableCell align="left">{appbgstyle}</TableCell>
                                                <TableCell align="left">
                                                    <Label color={status ? 'success' : 'error'}> {status ? 'Active' : 'Inactive'}</Label>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton size="large" color="inherit" onClick={() => handleClickOpen(row)}>
                                                        <Iconify icon={'material-symbols:edit-square-outline'} />
                                                    </IconButton>
                                                    <IconButton size="large" color="inherit" onClick={() => handleChangeStatus(row)}>
                                                        <Iconify icon={'mdi:archive-cancel-outline'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={showAppData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>


            <Dialog maxWidth="md" open={openModal}>
                {showModalAppData.app_name ? <DialogTitle>Edit ({showModalAppData.app_name}) </DialogTitle> : <DialogTitle>Add</DialogTitle>}
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                            <TextField
                                id="id"
                                {...register('id')}
                                label="id"
                                type="hidden"
                                defaultValue={showModalAppData.id}
                            />
                            <Grid item xs={6} sm={6} md={6}>

                                <TextField
                                    fullWidth
                                    id="app_name"
                                    label="Apps Name"
                                    defaultValue={showModalAppData.appname}
                                    {...register('app_name')}
                                    helperText={errors.appname?.message}
                                    error={Boolean(errors.app_name && 'error')}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    id="app_desc"
                                    label="Descriptions"
                                    defaultValue={showModalAppData.appdesc}
                                    {...register('app_desc')}
                                    helperText={errors.app_desc?.message}
                                    error={Boolean(errors.app_desc && 'error')}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    id="app_icon"
                                    label="Icon"
                                    defaultValue={showModalAppData.appicon}
                                    {...register('app_icon')}
                                    helperText={errors.app_icon?.message}
                                    error={Boolean(errors.app_icon && 'error')}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    id="app_bgstyle"
                                    label="App Background"
                                    defaultValue={showModalAppData.appbgstyle}
                                    {...register('app_bgstyle')}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    id="app_url"
                                    name="app_url"
                                    label="Apps Path"
                                    defaultValue={showModalAppData.appurl}
                                    {...register('app_url')}
                                    helperText={errors.app_url?.message}
                                    error={Boolean(errors.app_url && 'error')}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>

                                <Accordion >
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>สิทธิ์การเข้างาน Function</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Table
                                            className="table table-bordered table-hover"
                                            id="tab_logic"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="text-center"> # </TableCell>
                                                    <TableCell className="text-center"> Function Name </TableCell>
                                                    <TableCell className="text-center"> Function Detail </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {showModalFunctionData.rows.map((item, idx) => (
                                                    <TableRow id="addr0" key={idx}>
                                                        <TableCell>{idx + 1}</TableCell>
                                                        <TableCell>
                                                            <input type='hidden' value={showModalFunctionData.rows[idx].function_id} {...register(`detail_function.${idx}.function_id`)} />
                                                            <TextField
                                                                fullWidth
                                                                name="name"
                                                                defaultValue={showModalFunctionData.rows[idx].function_name}
                                                                {...register(`detail_function.${idx}.function_name`)}
                                                                helperText={errors.detail_function?.message}
                                                                error={Boolean(errors.detail_function && 'error')}
                                                                size='small'
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                name="function_detail"
                                                                defaultValue={showModalFunctionData.rows[idx].function_detail}
                                                                onChange={handleChange(idx)}
                                                                className="form-control"
                                                                {...register(`detail_function.${idx}.function_detail`)}
                                                                size='small'
                                                            />

                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="outlined"
                                                                onClick={handleRemoveSpecificRow(idx)}
                                                                color="error"
                                                            >
                                                                Remove
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Button onClick={handleAddRow} variant="outlined">
                                            Add Row
                                        </Button>

                                    </AccordionDetails>
                                </Accordion>


                            </Grid>



                            <Grid item xs={12}>
                                <FormControlLabel {...register('status')} defaultValue={showModalAppData.status} sx={{ mt: 1, width: 15 }} control={(showModalAppData.status === 1) && <Switch defaultChecked /> || <Switch />} label="Status" />

                            </Grid>

                        </Grid>

                        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" >
                            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                                <Button type="submit" variant="contained" color='primary' >
                                    Save
                                </Button>
                                <Button variant="contained" color='error' onClick={handleClose} >
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>

                    </Box>
                </DialogContent>

            </Dialog>




        </>
    );
}
