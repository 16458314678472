import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled,createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, Container, Typography, Divider,Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// Components
import Logo from '../components/logo';
// sections
import { RegisterForm } from '../sections/auth/register';


// ----------------------------------------------------------------------

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {

          backgroundImage: "url(/background.webp)"        }
      }
    }
  }
});

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '50vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));


// ----------------------------------------------------------------------

export default function RegisterPage() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  const handleLogin = async e => {
    e.preventDefault();
    navigate("/login");
}

  return (
    <>
      <Helmet>
        <title> Register </title>
      </Helmet>
      <ThemeProvider theme={theme}>
      <CssBaseline />
        <Box align="center">
              <Logo sx={{width:180,marginTop: 2}} />
        </Box>
        <Container maxWidth="sm">
           
          <StyledContent>
          
            <RegisterForm />
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider>
            <Typography variant="body2" sx={{ mb: 5 }}>
            Already have an account? {''}
              <Link variant="subtitle2" onClick={handleLogin} sx={{cursor:'pointer'}}>Sign In?</Link>
            </Typography>

          </StyledContent>
        </Container>
      </ThemeProvider>
    </>
  );
}
