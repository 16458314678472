import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import  moment  from 'moment';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { filter } from 'lodash';
import { redirect ,useNavigate} from 'react-router-dom';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Switch
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { TableListHead, TableToolbar } from '../sections/@dashboard/table';
// mock
const API_URL = `${process.env.REACT_APP_API_URL}`;


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'menu_name', label: 'Name', alignRight: false },
  { id: 'menu_url', label: 'Path', alignRight: false },
  { id: 'menu_order', label: 'Order', alignRight: false },
  { id: 'menu_icon', label: 'Icon', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action',label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_response) => _response.menu_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function MenuPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const bearer = "Bearer ";
    
    fetch(`${API_URL}/authen`, {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": bearer+token}
    })
    .then(response => response.json())
    .then(data => {
        if(data.status === 'ok'){
          console.log();
        }else{
            localStorage.clear();
            sessionStorage.clear()
            navigate("/login");
        }
    })
    .catch(() =>{
        localStorage.clear();
        sessionStorage.clear()
        redirect("/login");
    });
  });

  // Start Table
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const [menuList, setData] = useState([]);

  const [refreshData, setfreshData] = useState(false);

  const MySwal = withReactContent(Swal)


  useEffect(() => {
    const token = localStorage.getItem('token');
    const bearer = "Bearer ";
    fetch(`${API_URL}/menus`,{method: 'get',
        headers: {"Content-Type": "application/json", "Authorization": bearer+token}
    }).then(response => response.json())
    .then(data => 
      setData(data)
    )
  },[refreshData])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - menuList.length) : 1;

  const filteredUsers = applySortFilter(menuList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  // End Table

  const [openModal, setOpenModal] = React.useState(false);
  const [menuModal, setModalMenu] = useState(false);
  const handleClickOpen = (info) => {
      setModalMenu(info);
      setOpenModal(true);
  }

  const formSchema = Yup.object().shape({
    menu_name: Yup.string()
    .required('Enter Menu Name'),
    menu_url: Yup.string()
    .required('Enter Path'),
    menu_icon: Yup.string()
    .required('Enter Font Awesome Icon'),
    menu_order: Yup.string()
    .required('Enter Order By')
  })

  const formOptions = { resolver: yupResolver(formSchema) }

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors }
  } = useForm(formOptions, {defaultValues: { 
    id:'',
    menu_name: '',
    menu_url:'',
    menu_icon: '',
    menu_type:'',
    menu_order: ''
  }} );

  const onSubmit = (data) => {

      const jsonData = {
        id: data.id,
        menu_name: data.menu_name,
        menu_url: data.menu_url,
        menu_icon: data.menu_icon,
        status: data.status,
        menu_order: data.menu_order,
        create_date: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    
    fetch(`${API_URL}/crud_menu`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(data => {
      setOpenModal(false);
      MySwal.fire({
        title: 'บันทึกข้อมูลสำเร็จ',
        text: "success",
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      });
      setfreshData(!refreshData);

    })
    .catch((error) =>{
      console.error('Error: ',error);
    });
    
    
  }

  const handleClose = () => {
    resetField("id");
    resetField("menu_name");
    resetField("menu_url");
    resetField("menu_icon");
    resetField("menu_order");
    setOpenModal(false);
  };

  const handleChangeStatus = (data) => {

    let chkstatus = '0';
    if(data.status === 0){
      chkstatus = '1';
    }
    
  
    const jsonData = {
        id: data.id,
        menu_name: data.menu_name,
        menu_icon: data.menu_icon,
        menu_order: data.menu_order,
        menu_url: data.menu_url,
        status: chkstatus,
        create_date: moment().format('YYYY-MM-DD HH:mm:ss')
    };


    fetch(`${API_URL}/crud_menu`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
    .then(response => response.json())
    .then(data => {
    
      if(data.status ==='success'){
        setfreshData(!refreshData);
      }
      
    })
    .catch((error) =>{
      console.error('Error: ',error);
    });
    
    
  }
  

  return (
    <>
      <Helmet>
        <title> Menus </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Menus
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
            Create
          </Button>
          
        </Stack>

        <Card>

          <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={menuList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" >
                       
                        <TableCell component="th" scope="row">
                              {row.menu_name}
                        </TableCell>

                        <TableCell align="left">{row.menu_url}</TableCell>
                        <TableCell align="left">{row.menu_order}</TableCell>
                        <TableCell align="left">{row.menu_icon }</TableCell>
                        <TableCell align="left">
                          <Label color={row.status ? 'success' : 'error'}> {row.status ? 'Active' : 'Inactive'}</Label>
                        </TableCell>
                       
                        <TableCell align="center">
                        <IconButton size="large" color="inherit" onClick={() => handleClickOpen(row)} >
                          <Iconify icon={'material-symbols:edit-square-outline'} />
                        </IconButton>
                        <IconButton size="large" color="inherit" onClick={() => handleChangeStatus(row)}>
                          <Iconify icon={'mdi:archive-cancel-outline'} />
                        </IconButton>
                      </TableCell>
                      </TableRow>
                    );
                  })}
                  {!emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                       <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={menuList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog maxWidth="xs" open={openModal}>
        {menuModal.menu_name ? <DialogTitle>Edit ({menuModal.menu_name}) </DialogTitle>  : <DialogTitle>Add</DialogTitle>}  
        <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
                  <TextField 
                    id="id"
                    {...register('id')}
                    label="id"
                    type="hidden"
                    defaultValue={menuModal.id}                    
                    />
                <Grid item xs={12} sm={12} md={12}>
                  
                    <TextField
                    fullWidth
                    id="menu_name"
                    label="Menu Name"
                    defaultValue={menuModal.menu_name}
                    {...register('menu_name')}
                    helperText={errors.menu_name?.message}
                    error={Boolean(errors.menu_name && 'error')} 
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                    fullWidth
                    id="menu_url"
                    label="Path"
                    defaultValue={menuModal.menu_url}
                    {...register('menu_url')}
                    helperText={errors.menu_url?.message}
                    error={Boolean(errors.menu_url && 'error')} 
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                    fullWidth
                    id="menu_icon"
                    label="Icon"
                    defaultValue={menuModal.menu_icon}
                    {...register('menu_icon')}
                    helperText={errors.menu_icon?.message}
                    error={Boolean(errors.menu_icon && 'error')} 
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                    fullWidth
                    id="menu_order"
                    label="Order "
                    type="number"
                    defaultValue={menuModal.menu_order}
                    {...register('menu_order')}
                    helperText={errors.menu_order?.message}
                    error={Boolean(errors.menu_order && 'error')} 
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <FormControlLabel {...register('status')} defaultValue={menuModal.status} sx={{ mt: 1,width:15}} control={(menuModal.status === 1) &&<Switch defaultChecked/> ||<Switch  />} label="Status" />
                </Grid>

            </Grid>

            <Box display="flex" justifyContent="flex-end"alignItems="flex-end" >
            <Stack direction="row" spacing={1}  sx={{mt: 2}}>
              <Button type="submit" variant="contained" color='primary' >
                Save
              </Button>
              <Button variant="contained" color='error' onClick={handleClose} >
                Cancel
              </Button>
            </Stack>
          </Box>

        </Box>
        </DialogContent>
        
      </Dialog>    

     
    </>
  );
}
